import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import axios from "../utilities/axios";

const initialState = {
  articles: [],
  article: "",
  articlesStatus: "idle",
  updateArticleStatus: "idle",
  articleById: "",
  articleByIdStatus: "idle",
  updateCreateArticle: null,
  articlesError: null,
  categories: [],
  articleContent: "",
  createCategoryStatus: "",
  img: "",
  func: "",
  UploadImageStatus: "idle",
  createStatusArticle: "idle",
  categoriesStatus: "idle",
  editedArticle: "",
  categoriesError: null,
  statusDeleteArticle: "idle",
  errorDeleteArticle: null,
  statusCreateArticle: "idle",
  errorCreateArticle: null,
  isArticleEmpty: true,
};

export const getAllCategories = createAsyncThunk(
  "helpdesk/getAllCategories",
  async (websiteId, thunkApi) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteId}/articles-category`
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(getAllArticles(websiteId));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllArticles = createAsyncThunk(
  "helpdesk/getAllArticles",
  async (websiteId) => {
    let data;
    try {
      const response = await axios.get(`/websites/${websiteId}/articles`);
      data = response.data.data.docs;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createArticles = createAsyncThunk(
  "helpdesk/createArticles",
  async (body) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteID}/articles`,
        body.data
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOneArticleById = createAsyncThunk(
  "helpdesk/getOneArticleById",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/articles/${body.id}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateArticle = createAsyncThunk(
  "helpdesk/updateArticle",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(
        `/websites/${body.websiteID}/articles/${body.id}`,
        body.data
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getAllArticles(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createCategory = createAsyncThunk(
  "helpdesk/createCategory",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteID}/articles-category`,
        { name: body.name, description: body.description }
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getAllCategories(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteArticle = createAsyncThunk(
  "helpdesk/deleteArticle",
  async (body) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteID}/articles/${body.articleID}`
      );

      if (response.status === 200) {
        return body.articleID;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const UploadImage = createAsyncThunk(
  "helpdesk/UploadImage",
  async (body) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteID}/articles/uploadImage`,
        serialize({ file: body.file }, { indices: true })
      );

      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    getArticleContent: (state, action) => {
      state.articleContent = action.payload.data;
      state.isArticleEmpty = action.payload.isEmpty;
      // state.func = action.payload.func;
    },
    getOneArticle: (state, action) => {
      localStorage.setItem("article", JSON.stringify(action.payload));
      state.article = action.payload;
    },
    emptyArticleContent: (state, action) => {
      state.articleContent = "";
    },
    getEditedArticle: (state, action) => {
      state.editedArticle = action.payload;
    },
  },
  extraReducers: {
    [getAllArticles.pending]: (state) => {
      state.articlesStatus = "loading";
    },
    [getAllArticles.fulfilled]: (state, action) => {
      state.articlesStatus = "succeeded";
      state.articles = action.payload;
    },
    [getAllArticles.rejected]: (state, action) => {
      state.articlesStatus = "failed";
      state.articlesError = action.payload;
    },
    [getOneArticleById.pending]: (state) => {
      state.articleByIdStatus = "loading";
    },
    [getOneArticleById.fulfilled]: (state, action) => {
      state.articleByIdStatus = "succeeded";
      state.articleById = action.payload;
    },
    [getOneArticleById.rejected]: (state, action) => {
      state.articleByIdStatus = "failed";
      state.articlesError = action.payload;
    },
    [createCategory.pending]: (state) => {
      state.createCategoryStatus = "loading";
    },
    [createCategory.fulfilled]: (state, action) => {
      state.createCategoryStatus = "succeeded";
      state.articles = action.payload;
    },
    [createCategory.rejected]: (state, action) => {
      state.createCategoryStatus = "failed";
      // state.createCategoryStatus = action.payload;
    },
    [UploadImage.pending]: (state) => {
      state.UploadImageStatus = "loading";
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.UploadImageStatus = "succeeded";

      state.img = action.payload;
    },
    [UploadImage.rejected]: (state, action) => {
      state.UploadImageStatus = "failed";
      state.articlesError = action.payload;
    },
    [getAllCategories.pending]: (state) => {
      state.categoriesStatus = "loading";
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.categoriesStatus = "succeeded";
      state.categories = action.payload;
    },
    [getAllCategories.rejected]: (state, action) => {
      state.categoriesStatus = "failed";
      state.categoriesError = action.payload;
    },
    [deleteArticle.pending]: (state) => {
      state.statusDeleteArticle = "loading";
    },
    [deleteArticle.fulfilled]: (state, action) => {
      state.statusDeleteArticle = "succeeded";
      state.articles = state.articles.filter(
        (article) => article._id !== action.payload
      );
    },
    [deleteArticle.rejected]: (state, action) => {
      state.statusDeleteArticle = "failed";
      state.errorDeleteArticle = action.payload;
    },
    [createArticles.pending]: (state) => {
      state.createStatusArticle = "loading";
    },
    [createArticles.fulfilled]: (state, action) => {
      state.statusDeleteArticle = "succeeded";
      state.articles = [...state.articles, action.payload];
    },
    [createArticles.rejected]: (state, action) => {
      state.statusDeleteArticle = "failed";
      state.errorDeleteArticle = action.payload;
    },
    [updateArticle.pending]: (state) => {
      state.createStatusArticle = "loading";
    },
    [updateArticle.fulfilled]: (state, action) => {
      state.statusDeleteArticle = "succeeded";
      state.articles = state.articles.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
    },
    [updateArticle.rejected]: (state, action) => {
      state.statusDeleteArticle = "failed";
      state.errorDeleteArticle = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const { getArticleContent, emptyArticleContent, getOneArticle } =
  slice.actions;

export default slice;
